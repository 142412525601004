import React from 'react';
import { Container } from '@mui/material';
import EmbedVideo from '../EmbedVideo/EmbedVideo';

import './InfoBox.scss';

export default function InfoBox({ data, infoId }) {
  const infoData = data.filter((it) => it.rowId === infoId)[0];
  const { title, description, image, video } = infoData;

  return (
    <div className="info-box">
      <Container maxWidth="sm">
        <h3>{ title }</h3>
        <p>{ description }</p>
        { image ? <img src={ image.src } alt={ image.alt } /> : null }
        { video ? <EmbedVideo src={ video } /> : null }
      </Container>
    </div>
  )
}