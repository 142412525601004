import React from 'react';
import CheckedTable from '../CheckedTable/CheckedTable';
import ShowInfo from '../InfoBox/InfoBox';
import { Container } from '@mui/material';

import './PackageSection.scss';

export default function PackageSection({
    user,
    data,
    curPackageId,
    showInfo,
    onShowInfoHandler,
    onSendEmailHandler
  }) {

  const [ filteredData ] = data.filter( d => d.id === curPackageId );

  return (
    <div className="package-section">
      {
        showInfo ? <ShowInfo data={ filteredData.checkedTable } infoId={ showInfo }/> :
        <>
          <Container className="custom-heading" maxWidth="sm">
            <h3>{ filteredData.packageTitle }</h3>
          </Container>
          <div className="checked-table-wrap">
            <CheckedTable
              tableData={ filteredData.checkedTable }
              user={ user }
              curPackageId={ curPackageId }
              onInfoClickHandler={ onShowInfoHandler }
              sendEmailHandler={ onSendEmailHandler }
              />
          </div>
        </>
      }
    </div>
  )
}