import React from 'react';
import CustomCard from '../CustomCard/CustomCard';
import { Container } from '@mui/system';

import './ChoosePackages.scss';

export default function ChoosePackages({ data, clickPackageHandler }) {
  const cards = data.map(({ packageTitle, id }) => {
    return <CustomCard key={`package-${id}`} cardClickHandler={() => clickPackageHandler(id)}>{ packageTitle }</CustomCard>
  });

  return (
    <div className="choose-packages">
      <Container maxWidth="sm">
        <p>Choose from one of your packages to continue</p>
        { cards }
      </Container>
    </div>
  )
}