import React from 'react';
import { signout } from '../../firebase/firebase';
import './LogoutButton.scss';

export default function LogoutButton({ logout }) {
  const handleSignOut = (e) => {
    e.preventDefault();
    signout(success, error)
  };
  const success = () => logout();
  const error = (er) => console.error(er.message);
  return <a href="/" className="logout-button" onClick={handleSignOut}>Logout</a>;
}