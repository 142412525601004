import React, { useState, useEffect } from 'react';
import { AppBar } from '@mui/material';
import SignIn from './components/SignIn/SignIn';
import LogoutButton from './components/LogoutButton/LogoutButton';
import BackButton from './components/BackButton/BackButton';
import ChoosePackages from './components/ChoosePackages/ChoosePackages';
import PackageSection from './components/PackageSection/PackageSection';
import OrderSentMessage from './components/OrderSentMessage/OrderSentMessage';
import { fakeData } from './fakeData/toolboxData';

import './App.scss';

function App() {
  const [ user, setUser ] = useState(null);
  const [ step, setStep ] = useState(0);
  const [ data, setData ] = useState([]);
  const [ curPackageId, setCurPackageId ] = useState(null);
  const [ showInfo, setShowInfo ] = useState(false);

  useEffect(() => {
    setData(fakeData);
  }, []);

  const userLoginHandler = (userCredential) => {
    const user = userCredential.user.email;
    setUser(user);
    setStep(1);
  }

  const userLogoutHandler = () => {
    setStep(0);
  }

  const backButtonClickHandler = () => {
    if ( showInfo ) {
      setShowInfo(false);
      return;
    }
    setStep(step - 1);
  }

  const choosePackageHandler = (id) => {
    setCurPackageId(id);
    setStep(step + 1);
  }

  const showInfoHandler = (rowId) => {
    setShowInfo(rowId);
  }

  const sendEmailHandler = () => {
    setStep(step + 1);
  }

  const appBar = (
    step ?
      <AppBar
        className="app-header"
        position="static"
        sx={{ p: 1 }}>
          { step > 1 ? <BackButton backClickHandler={backButtonClickHandler}/> : null }
          <LogoutButton logout={userLogoutHandler}/>
      </AppBar> : null
  )

  return (
    <>
      { appBar }
      <div className="main-container">
        {
          step === 1 ? 
            <ChoosePackages
              data={ data }
              clickPackageHandler={choosePackageHandler}/> :
          step === 2 ? 
            <PackageSection
              user={ user }
              data={ data }
              curPackageId={ curPackageId }
              showInfo={ showInfo }
              onShowInfoHandler={ showInfoHandler }
              onSendEmailHandler={ sendEmailHandler }/> :
          step === 3 ? 
            <OrderSentMessage message="Order has been sent" /> :
            <SignIn login={ userLoginHandler }/> 
        }
      </div>
    </>
  );
}

export default App;