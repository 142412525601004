import React from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './CustomCard.scss';

export default function CustomCard({ children, cardClickHandler }) {
  return (
    <div className="custom-card" onClick={ cardClickHandler }>
      <div className="custom-card-holder">
        <h3>{ children }</h3>
        <KeyboardBackspaceIcon fontSize="large" className="arrow-next"/>
      </div>
    </div>
  )
}