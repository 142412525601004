import React from 'react';
import { Container } from '@mui/system';

import './OrderSentMessage.scss';

export default function SendMessage({ message }) {
  return (
    <Container maxWidth="sm">
      <div className="order-sent-message">
        <h2>{ message }</h2>
      </div>
    </Container>
  )
}
