export const postToServer = (url, postData) => {
  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postData)
  });
}

export const serverUrl = 'https://toolboxim.preview.saentys.com/php/sendEmail.php';