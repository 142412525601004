import React from 'react';

import './EmbedVideo.scss';

export default function EmbedVideo({ src }) {
  return (
    <div className="video-container">
      <iframe src={ src } title="video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
    </div>
  )
}
