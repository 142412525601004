import { databaseId } from "../fakeData/toolboxData";

const errorMessage = (e) => console.error(e);

const setStore = (db) => {
  const transaction = db.transaction(['users'], 'readwrite');
  const store = transaction.objectStore('users');
  return store;
}

export const openDB = (f) => {
  let db;
  const openRequest = indexedDB.open(`toolbox_db-${databaseId}`, 1);

  openRequest.onupgradeneeded = function(e) {
    db = e.target.result;
    if (!db.objectStoreNames.contains('users')) db.createObjectStore('users', {keyPath: 'user'});
  };

  openRequest.onsuccess = (e) => {
    db = e.target.result;
    f(db);
  };

  openRequest.onerror = errorMessage;
}

export const putItem = (db, item) => {
  const store = setStore(db);
  const request = store.put(item);
  request.onerror = errorMessage;
}

export const getItem = (db, key, f) => {
  const store = setStore(db);
  const request = store.get(key);
  request.onerror = errorMessage;
  request.onsuccess = () => f(request.result);
}