import image from '../images/image.jpeg';

export const databaseId = "project_id"; // change id for create new database

export const fakeData = [
  {
    "packageTitle": "CBRE inhouse IM and you need assets only",
    "id": "package1",
    "checkedTable": [
      {
        "rowId": "1",
        "name": "Simple branding (logo)",
        "cost": 800,
        "qty": 1,
        "title": "Example of Asset #1",
        "description": "Short description, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non dicta blanditiis exercitationem amet quia eos illum quod iste at, optio quaerat reiciendis accusantium veniam itaque laborum rerum harum! Cupiditate, eos?",
        "image": {
          "src": image,
          "alt": "image description"
        },
        "video": "https://www.youtube.com/embed/Fkm3mtcpspE"
      },
      {
        "rowId": "2",
        "name": "Macro/Micro map",
        "cost": 600,
        "qty": 1,
        "title": "asset title",
        "description": "lorem...",
        "image": {
          "src": image,
          "alt": "image description"
        },
        "video": "https://player.vimeo.com/video/28164488?h=2589650d64"
      },
      {
        "rowId": "3",
        "name": "Axonometric model small",
        "cost": 600,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "4",
        "name": "Axonometric model large",
        "cost": 1500,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "5",
        "name": "OS plan",
        "cost": 300,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "6",
        "name": "Floorplan",
        "cost": 100,
        "qty": 1,
        "title": "asset title",
        "description": "lorem...",
        "additionalSum": 300
      },
      {
        "rowId": "7",
        "name": "Pie chart/custom icons or other (Custom/Vector)",
        "cost": 50,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "8",
        "name": "Dataroom (Client brand)",
        "cost": 1200,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "9",
        "name": "Photography (2000CHF /day)",
        "cost": 2000,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
    ]
  },
  {
    "packageTitle": "Beskope IM and you need Saentys to produce it",
    "id": "package2",
    "defaultTable": [
      {
        "name": "Numbers of pages required",
        "qty": 50
      },
      {
        "name": "Numbers of floors for floorplan",
        "qty": 10
      },
      {
        "name": "Languages",
        "qty": 1
      },
      {
        "name": "Print copies(if required)",
        "qty": 0
      }
    ],
    "checkedTable": [
      {
        "rowId": "1",
        "name": "Branding",
        "cost": 1800,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "2",
        "name": "IM Number of pages",
        "cost": 150,
        "qty": 30,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "3",
        "name": "IM - Digital print - 40 pp",
        "cost": 40,
        "qty": 0,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "4",
        "name": "Photography (2000CHF /day)",
        "cost": 2000,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "5",
        "name": "Macro/Micro map",
        "cost": 600,
        "qty": 2,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "6",
        "name": "Axonometric model Small",
        "cost": 600,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "7",
        "name": "Axonometric model Large",
        "cost": 1500,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "8",
        "name": "OS plan",
        "cost": 300,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "9",
        "name": "Floorplan",
        "cost": 100,
        "qty": 10,
        "title": "asset title",
        "description": "lorem...",
        "additionalSum": 300
      },
      {
        "rowId": "10",
        "name": "Language(s)",
        "multiplyRow": "2",
        "multiply": 0.2,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "11",
        "name": "Film",
        "cost": 5000,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "12",
        "name": "Teaser (2 Seiten)",
        "cost": 800,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      },
      {
        "rowId": "13",
        "name": "Dataroom (Client brand)",
        "cost": 1200,
        "qty": 1,
        "title": "asset title",
        "description": "lorem..."
      }
    ]
  }
]