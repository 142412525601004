import React from 'react';

import './NumInput.scss';

export default function NumInput({ val, rowId, onInputChange }) {

  const onChagneHandler = (e) => {
    let curVal = e.target.value;
    if (curVal < 1) curVal = '';
    onInputChange(rowId, curVal);
  }

  const getWidth = () => {
    const vLength =  val.toString().length;
    const wStep = vLength > 2 ? vLength : 1;
    return `${20 + ( 7 * wStep )}px`;
  }

  return (
    <input 
      className="num-input"
      type="number"
      value={ val }
      style={{ width: getWidth() }}
      onChange={ onChagneHandler } /> 
  )
}
