import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyDS9v6ExboEQtD9Dw5dlDuMhviTHlp03uM",
//   authDomain: "auth-demo-ed6f1.firebaseapp.com",
//   projectId: "auth-demo-ed6f1",
//   storageBucket: "auth-demo-ed6f1.appspot.com",
//   messagingSenderId: "799691673478",
//   appId: "1:799691673478:web:9e8fec0d69e9d65d8ce607"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCi4GvTi209B7JT8pF0Ricv6I6_ZJEKEQk",
  authDomain: "toolboxim-c832f.firebaseapp.com",
  projectId: "toolboxim-c832f",
  storageBucket: "toolboxim-c832f.appspot.com",
  messagingSenderId: "402519151839",
  appId: "1:402519151839:web:ef517e538b21fef5f1456a"
};

initializeApp(firebaseConfig);

const auth = getAuth();

export function signin(email, password, successCb, errorCb) {
  return signInWithEmailAndPassword(auth, email, password)
  .then(successCb).catch(errorCb);
}

export function signout(successCb, errorCb) {
  signOut(auth).then(successCb).catch(errorCb);
}

