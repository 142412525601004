import React, { useState, useEffect } from 'react';
import { Container, Checkbox } from '@mui/material';
import TableRow from '../TableRow/TableRow';
import CheckedTableResults from '../CheckedTableResults/CheckedTableResults';
import { openDB, putItem, getItem } from '../../indexedDB/indexedDB';
import './CheckedTable.scss';

export default function CheckedTable({ user, tableData, curPackageId, onInfoClickHandler, sendEmailHandler }) {
  const [ isCheckAll, setIsCheckAll ] = useState(false);
  const [ isCheck, setIsCheck ] = useState([]);
  const [ isQty, setIsQty ] = useState({});
  const [ modifiedData, setModifiedData ] = useState([]);

  const cbList = tableData.map((l) => l.rowId);

  useEffect(() => {
    openDB(db => getItem(db, user, (data) => {
      let isChecked = [];
      let isQuantity = {};
      if ( data && data[curPackageId] ) {
        isChecked = data[curPackageId].checked ? data[curPackageId].checked : [];
        isQuantity = data[curPackageId].qty ? data[curPackageId].qty : {};
      }

      if (isChecked.length === cbList.length) setIsCheckAll(true);
      setIsCheck(isChecked);
      setIsQty(isQuantity);
      changeQtyInModData(tableData, isQuantity);
    }));
  },[ curPackageId, user, cbList.length, tableData ]);

  const setUserCheckedData = (isChecked) => {
    openDB(db => getItem(db, user, (data) => {
      const d = data ? data : { user };
      if (!d[curPackageId]) d[curPackageId] = {};
      d[curPackageId].checked = isChecked;
      putItem(db, d);
    }));
  }

  const setUserQtyData = (qty) => {
    openDB(db => getItem(db, user, (data) => {
      const d = data ? data : { user };
      if (!d[curPackageId]) d[curPackageId] = {};
      d[curPackageId].qty = d[curPackageId.qty] ? Object.assign(d[curPackageId].qty, qty) : qty;
      putItem(db, d);
    }));
  }

  const changeQtyInModData = (tableData, isQty) => {
    const td = JSON.parse(JSON.stringify(tableData));
    for (const key in isQty ) {
      td.forEach((it) => {
        if (key === it.rowId) {
          it.qty = isQty[key];
        }
      });
    }
    setModifiedData(td);
  }

  const onCheckAllHandler = () => {
    setIsCheckAll(!isCheckAll);
    const isChecked = isCheckAll ? [] : cbList;
    setIsCheck( isChecked );
    setUserCheckedData( isChecked );
  }

  const onCbChangeHandler = (e) => {
    const { id, checked } = e.target;
    const isChecked = checked ? [...isCheck, id] : isCheck.filter(item => item !== id);
    const checkAllState = isChecked.length === cbList.length ? true : false;
    setIsCheck( isChecked );
    setUserCheckedData( isChecked );
    setIsCheckAll(checkAllState);
  }

  const onInpChangeHandler = (id, val) => {
    const qty = isQty;
    qty[id] = val;
    setIsQty( qty );
    setUserQtyData(qty);
    changeQtyInModData(tableData, isQty);
  }

  const tableRows = modifiedData.map(({ rowId, name, qty }) => {
    return (
      <TableRow key={`row-id-${ rowId }`}
        rowId={ rowId }
        cols={[ name, qty ]}
        isChecked={ isCheck }
        cbChangeHandler={ onCbChangeHandler }
        inpChangeHandler={ onInpChangeHandler }
        infoClickHandler={ onInfoClickHandler } />
    )
  });

  return (
    <div className="checked-table-holder">
      <Container className="table-container" maxWidth="sm">
        <table className="checked-table">
          <thead>
            <tr>
              <th>
                <Checkbox
                  className="table-checkbox"
                  onChange={ onCheckAllHandler }
                  checked={ isCheckAll }/>
                Asset
              </th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            { tableRows }
          </tbody>
        </table>
      </Container>
      <CheckedTableResults data={ modifiedData } isCheck={ isCheck } user={ user } onSendEmail={ sendEmailHandler }/>
    </div>
  )
}