import React, { useRef, useState } from 'react';
import { TextField, Button } from '@mui/material';
import { signin } from '../../firebase/firebase';
import { Container } from '@mui/system';
import './SignIn.scss';

export default function SignIn({ login }) {
  const [ errorMsg, setErrorMsg ] = useState(null);
  const emailRef = useRef();
  const passwordRef = useRef();

  const success = (userCredential) => login(userCredential);
  const error = (er) => setErrorMsg(er.message);

  const handleSignIn = (e) => {
    e.preventDefault();
    signin(emailRef.current.value, passwordRef.current.value, success, error);
  }

  const errorMsgBlock = (
    <div className="error-msg">
      { errorMsg }
    </div>
  )

  return (
    <Container maxWidth="sm">
      <form className="signin-form" onSubmit={handleSignIn}>
        { errorMsg ? errorMsgBlock : null }
        <TextField
          fullWidth
          label="email"
          variant="outlined"
          type="email"
          size="small"
          inputRef={emailRef}
          sx={{ m: 1 }}/>
        <TextField 
          fullWidth
          label="password"
          variant="outlined"
          type="password"
          size="small"
          inputRef={passwordRef}
          sx={{ m: 1 }}/>
        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ m: 1 }}>
            LOGIN
        </Button>
      </form>
    </Container>
  )
}
