import React from 'react';
import './CalcResults.scss';

export default function CalcResults({ totalCost, totalHours }) {
  return (
    <dl className="calc-results">
      <dt>Total cost:</dt>
      <dd>{ totalCost } CHF</dd>
      <dt>Delivery time:</dt>
      <dd>{ Math.ceil(totalHours / 8) } days</dd>
    </dl>
  )
}
