import React from 'react';
import { Checkbox } from '@mui/material';
import InfoButton from '../InfoButton/InfoButton';
import NumInput from '../NumInput/NumInput';
import './TableRow.scss';

export default function TableRow({
    rowId,
    cols,
    isChecked,
    cbChangeHandler,
    inpChangeHandler,
    infoClickHandler 
  }) {

  const checked = isChecked ? isChecked.includes(rowId) : null;
  const classes = `table-row${checked ? ' checked' : ''}`;
  const columns = cols.map((col, i) => {
    const row =
      i ?
        <NumInput 
          val={ col }
          rowId={ rowId }
          onInputChange={ inpChangeHandler }
        />
      :
        <div className="table-col-flex">
          { isChecked ? <Checkbox id={ rowId } checked={ checked } onChange={ cbChangeHandler } /> : null }
          <span className="text-wrap">
            { col }
            { infoClickHandler ? <InfoButton onInfoClick={ infoClickHandler } rowId={ rowId }/> : null }
          </span>
        </div>
    return (
      <td key={`col-id-${i}`}>
        { row }
      </td>
    )
  });

  return (
    <tr className={classes}>
      { columns }
    </tr>
  )
}
