import React from 'react';
import CalcResults from '../CalcResults/CalcResults';
import { Container } from '@mui/system';
import { postToServer, serverUrl } from '../../utils/postToServer';

import './CheckedTableResults.scss';

export default function CheckedTableResults({ user, data, isCheck, onSendEmail }) {
  const calcTotal = (data, isCheck) => {
    let totalCost = 0;
    let totalHours = 0;
    let checked = '';
  
    data.forEach((it) => {
      if (isCheck.includes(it.rowId)) {
        const additionalSum = it.additionalSum || 0;
        checked += `${it.name} - ${it.qty}<br>`;
  
        if (it.multiplyRow) {
          if (isCheck.includes(it.multiplyRow)) {
            data.forEach((cur) => {
              if (cur.rowId === it.multiplyRow) {
                const total = cur.cost * cur.qty * it.multiply;
                totalCost += total * it.qty;
                totalHours += (total / 150) * it.qty;
              }
            });
          }
          return;
        }
  
        const cost = it.cost * it.qty;
        totalHours += (it.cost / 150) * it.qty;
        totalCost += cost ? cost + additionalSum : cost;
      }
    });

    return { totalCost, totalHours, checked }
  }

  const { totalCost, totalHours, checked } = calcTotal(data, isCheck);

  const createHtmlStr = () => {
    let str = '';
    str += `<b>${user}</b><br><br>`
    str += checked;
    str += '<br>';
    str += `<b>Total Cost:</b> ${totalCost} CHF<br>`;
    str += `<b>Total Hours:</b> ${Math.ceil(totalHours / 8)} days<br>`;
    return str;
  }

  const printHandler = (e) => {
    e.preventDefault();
    window.print();
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const postData = {
      user,
      htmlStr: createHtmlStr()
    }

    postToServer(serverUrl, postData);
    onSendEmail();
  }

  return (
    <div className="checked-table-results">
      <Container maxWidth="sm">
        <CalcResults totalCost={ totalCost } totalHours={ totalHours }/>
        <div className="btn-wrap">
          <a href="/" className="pdf-btn" onClick={ printHandler }>Generate PDF</a> |
          <a className={`order-btn${!isCheck.length ? ' disabled' : ''}`} onClick={ sendEmail } href="/"> Order</a></div>
      </Container>
    </div>
  )
}